import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import HeroContainer from '../components/HeroContainer/HeroContainer';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer'
import HackLabel from '../components/HackLabel/HackLabel';
import HackCarousel from '../components/HackCarousel/HackCarousel';
import useMarkdownRenderer from '../hooks/useMarkdownRenderer';
import useCriteoScript from '../hooks/useCriteoScript';
import { getBranchLinkParams, getPlaceHolderHacks, tan6 } from '../utils/utils';
import { getHacks } from '../utils/api';
import SEOHelmet from '../helmet/SEOHelmet';
import InsightsContainer from '../components/InsightsContainer/InsightsContainer';
import DownloadComponent from '../components/DownloadComponent/DownloadComponent';
import { sectionsToMarkdownString } from '../utils/hackTools';
import useBranchLink from '../hooks/useBranchLink';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import TranslatedContentComponent from '../components/TranslatedContentComponent/TranslatedContentComponent';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
      backgroundColor: '#F8F8F8',
    },
    label: {
      position: 'absolute',
      top: '-18px',
      marginBottom: '0',
    },
    heroContainer: {
      backgroundColor: theme.palette.primary.main,
      overflow: 'visible',
      [theme.breakpoints.up(1020)]: {
        marginBottom: `calc((1020px * ${tan6} * -1) / 2)`,
        paddingBottom: `calc((1020px * ${tan6} * -1) / 2)`,
      },
      [theme.breakpoints.up(1170)]: {
        marginBottom: `calc((1020px * ${tan6} * -1) / 2)`,
        paddingBottom: `calc((1020px * ${tan6} * -1) / 2)`,
      },
    },
    heroContainerContent: {
      paddingTop: 0,
      [theme.breakpoints.up(1200)]: {
        maxWidth: '1020px',
      },
    },
    playerContainer: {
      width: '100%',
      height: '104vw',
      [theme.breakpoints.up(423)]: {
        height: '440px',
      },
      [theme.breakpoints.up(1200)]: {
        height: '304px'
      }
    },
    player: {
      display: 'block',
      position: 'relative',
      width: '100%',
      height: 'calc((100vw - 48px) * 1.78)',
      border: 'none',
      margin: '0 auto 36px',
      backgroundColor: '#E9E9EC',
      borderRadius: 'calc((100vw - 48px) * 0.0487805)',
      [theme.breakpoints.up(423)]: {
        width: '375px',
        height: '668px',
        borderRadius: '18.2927px',
      },
      [theme.breakpoints.up(1200)]: {
        width: '100%',
        marginBottom: '48px',
        height: '544.312px',
        borderRadius: 'calc(972px * 0.0175)',
      }
    },
    title: {
      fontSize: '34px',
      lineHeight: '48px',
      '& span': {
        display: 'block',
      }
    },
    contribs: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: '500',
      marginTop: '2px',
    },
    subtitle: {
      fontSize: '17px',
      lineHeight: '24px',
      color: '#4F4F4F',
      fontWeight: '700',
      marginBottom: '8px',
    },
    text: {
      fontSize: '15px',
      lineHeight: '21px',
      color: '#4F4F4F',
      '& p': {
        '&:last-of-type': {
          margin: '0',
        }
      },
    },
    inANutshell: {
      marginTop: '24px',
      marginBottom: '32px',
    },
    introduction: {
      marginBottom: '10px',
    },
    content: {
      padding: '32px 24px 48px',
      maxWidth: '600px',
      margin: '-123.733vw auto 0',
      [theme.breakpoints.up(423)]: {
        marginTop: '-523.391px',
      },
      [theme.breakpoints.up(1200)]: {
        paddingTop: '56px',
        paddingBottom: '72px',
        maxWidth: '1020px',
        marginTop: '-531px',
      }
    },
    contentInner: {
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '28px',
      }
    },
    contentLeft: {
      [theme.breakpoints.up(1200)]: {
        flex: 1,
      },
    },
    favouriteQuote: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '16px',
      marginBottom: '32px',
    },
    quoteImage: {
      display: 'block',
      width: '66px',
    },
    favouriteQuoteText: {
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
      marginBottom: '8px',
    },
    favouriteQuoteAuthor: {
      fontSize: '13px',
      lineHeight: '17px',
      fontWeight: '400',
      color: theme.palette.primary.main,
      margin: '0',
    },
    hackCarouselContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#FFF',
      padding: '32px 0 12px',
      [theme.breakpoints.up(1200)]: {
        padding: '48px 0',
      },
    },
    hackCarouselInner: {
      width: '100%',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '1170px',
      }
    },
    carouselTitle: {
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
      margin: '0 0 16px',
      padding: '0 24px',
      [theme.breakpoints.up(1200)]: {
        padding: '0 48px',
      },
    },
    downloadComponent: {
      backgroundColor: 'unset !important',
    }
  };
});

const { renderer, marked } = useMarkdownRenderer();
const getMarkdownText = (text: string) => {
  return { __html: marked(text, { renderer }) };
};

const placeholderHacks = getPlaceHolderHacks();

const HackPostPage = ({ data, location, pageContext }: any) => {
  const classes = useStyles();
  const { language } = useI18next();
  const { t } = useTranslation();
  const iframeRef = useRef();
  const { trackCriteoButtonClick } = useCriteoScript();
  const [hacks, setHacks] = useState(placeholderHacks);
  const hack = data?.hack;

  const inANutshellText = sectionsToMarkdownString(hack.segments.inANutshell.sections);
  const introductionText = sectionsToMarkdownString(hack.segments.introduction.sections);

  const { branchLink } = useBranchLink(getBranchLinkParams({
    ...hack,
    id: hack.jsonId
  }));
  const branchId = branchLink ? new URL(branchLink).pathname : '';

  const webAppUrl = process.env.GATSBY_STAGE === 'prod' ? 'https://web.uptime.app/' : 'https://uptime-staging-web-app.netlify.app/';
  const apiUrl = process.env.GATSBY_STAGE === 'prod' ? '/prod-uptime-global-api/' : `/staging-uptime-global-api/`;

  useEffect(() => {
    getHacks({ setHacks, placeholderHacks, language: language.split('-')[0] });
  }, [setHacks]);

  useEffect(() => {
    if (iframeRef.current) {
      function handler(event) {
        try {
          const data = JSON.parse(event.data);
          if (data.context === 'iframe.resize') {
            iframeRef.current.style.setProperty('height', `${data.height}px`);
          }
        } catch (err) {
        }
      }
      window.addEventListener('message', handler);

      return () => {
        window.removeEventListener('message', handler);
      }
    }
  }, [iframeRef.current]);
  const contribs = hack?.contributors.map(({ name }) => name) || [];
  const oembedLocale = pageContext?.language === 'en-US' ? '' : `${pageContext?.language}/`

  return (
    <>
      <SEOHelmet
        title={t('hackPage.seo.title', { title: hack?.title, author: contribs.join(', ') })}
        description={t('hackPage.seo.description', { title: hack?.title, author: contribs.join(', ') })}
        location={location}
        image={{
          url: hack?.coverUrl,
          alt: `${hack?.title} cover image`,
        }}
        locale={pageContext?.language}
        overrideTitle
      />
      <Helmet>
        <link
          rel="alternate"
          type="application/json+oembed"
          href={`${apiUrl}embed/oembed?url=https://uptime.app/${oembedLocale}hack/${hack.jsonId}`}
        />
        <link rel="canonical" href={location.href} />
        <meta name="apple-itunes-app" content="app-clip-bundle-id=com.uptime.uptime.Clip, app-clip-display=card" />
      </Helmet>
      <AppBar variant="navy" keepBackgroundColor />
      <main className={classes.root}>
        <HeroContainer classes={classes}>
          <div className={classes.playerContainer} />
        </HeroContainer>
        <div className={classes.content}>
          <iframe
            ref={iframeRef}
            className={classes.player}
            src={`${webAppUrl}embed/hack/${hack.jsonId}${branchId ? `?bid=${branchId}` : ''}`}
            onLoad={(e) => {
              e.target.style.backgroundColor = 'unset';
            }}
          />
          <div className={classes.contentInner}>

            <div className={classes.contentLeft}>
              <Typography
                className={classes.title}
                variant="h1"
              >
                <HackLabel hackType={hack.type} />
                <span>{hack.title}</span>
                <span className={classes.contribs}>{t('hackPage.by')} {hack.contributors.map(({ name }) => name).join(', ')}</span>
              </Typography>
              {hack.baseLocaleHackId !== hack.jsonId && (
                <div style={{ marginTop: '24px' }}>
                  <TranslatedContentComponent />
                </div>
              )}
              <div className={classes.inANutshell}>
                <Typography className={classes.subtitle} variant="h2">
                  {t('hackPage.inANutshell')}
                </Typography>
                <div
                  className={classes.text}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={getMarkdownText(inANutshellText)}
                />
              </div>
              <div className={classes.favouriteQuote}>
                <img className={classes.quoteImage} src="/img/hack-page/quote-icon.png" alt="" />
                <div style={{ flex: 1 }}>
                  <Typography className={classes.subtitle} variant="h2">
                    {t('hackPage.favoriteQuote')}
                  </Typography>
                  <Typography className={classes.favouriteQuoteText} variant="body1">
                    {hack.segments.favouriteQuote.quote}
                  </Typography>
                  <Typography className={classes.favouriteQuoteAuthor} variant="body1">
                    {hack.segments.favouriteQuote.author}
                  </Typography>
                </div>
              </div>
              <div className={classes.introduction}>
                <Typography className={classes.subtitle} variant="h2">
                  {t('hackPage.introduction')}
                </Typography>
                <div
                  className={classes.text}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={getMarkdownText(introductionText)}
                />
              </div>
            </div>
            <InsightsContainer
              insights={hack.segments.insights}
              linkUrl={branchLink}
            />
          </div>
        </div>
        <div className={classes.hackCarouselContainer}>
          <div className={classes.hackCarouselInner}>
            <h3 className={classes.carouselTitle}>
              {t('hackPage.carouselTitle')}
            </h3>
            <HackCarousel hacks={hacks} setFilter={() => { }} hideFilters />
          </div>
        </div>
        <DownloadComponent
          className={classes.downloadComponent}
          showPhones
        />
      </main>
      <Footer />
    </>
  );
};

export default HackPostPage;

export const query = graphql`
  query HackPage($hackId: String, $topics: [String], $language: String) {
    hack(jsonId: { eq: $hackId }) {
      baseLocaleHackId
      jsonId
      title
      coverUrl
      segments {
        inANutshell {
          sections {
            cards {
              markdown
            }
          }
        }
        introduction {
          sections {
            cards {
              markdown
            }
          }
        }
        favouriteQuote {
          quote
          author
        }
        insights
      }
      theme {
        templates {
          overview {
            a {
              backgroundImageUrl
            }
          }
        }
      }
      type
      contributors {
        name
      }
      affiliate {
        name
        url
      }
    }
    allHack(
      filter: { topics: { elemMatch: { name: { in: $topics } } }, jsonId: { ne: $hackId } }
      sort: { fields: createdAt, order: DESC }
      limit: 25
    ) {
      edges {
        node {
          jsonId
          title
          coverUrl
          contributors {
            name
          }
          type
          affiliate {
            url
          }
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
